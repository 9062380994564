import { createSlice } from "@reduxjs/toolkit";
import { login } from "store/actions/authAsyncThunk";
import {
  updateClient,
  getClientById,
  sendPushToken,
  getPayments,
  getMethods,
  getPaymentDetails,
  addCard,
  deleteCard,
  setDefaultCard,
  updateClientPassword,
  generateInvoice,
  updateNickname,
  getSubscription,
} from "../actions/clientAsyncThunk";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: null,
    loading: "idle",
    currentRequestId: undefined,
    error: null,
    success: false,
    payments: null,
    methods: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: {
    //client
    [login.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [login.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.client = action.payload.client;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [login.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [getClientById.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getClientById.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.client = action.payload;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getClientById.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [getMethods.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getMethods.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.methods = action.payload.cards;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getMethods.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [getSubscription.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getSubscription.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.subscription = action.payload;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getSubscription.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },

    [updateClient.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [updateClient.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.client = action.payload.client;
        state.currentRequestId = undefined;
        state.success = true;
        state.error = null;
      }
    },
    [updateClient.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [updateNickname.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [updateNickname.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.error = null;
        state.success = true;
      }
    },
    [updateNickname.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },

    [updateClientPassword.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [updateClientPassword.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [updateClientPassword.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },

    //payments
    [getPayments.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getPayments.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.payments = action.payload;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getPayments.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    //payment details
    [getPaymentDetails.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getPaymentDetails.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getPaymentDetails.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [generateInvoice.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [generateInvoice.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.success = true;
        state.error = null;
      }
    },
    [generateInvoice.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [addCard.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [addCard.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.methods && state.methods.push(action.payload.card);
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [addCard.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [deleteCard.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [deleteCard.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        const cardIndex = state.methods.findIndex(
          (x) => x.id === action.payload.cardId
        );
        state.methods.splice(cardIndex, 1);
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [deleteCard.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [setDefaultCard.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [setDefaultCard.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        const cardIndex = state.methods.findIndex(
          (x) => x.id === action.payload.cardId
        );
        const defaultIndex = state.methods.findIndex((x) => x.default === true);
        state.methods[cardIndex].default = true;
        if (defaultIndex >= 0) {
          state.methods[defaultIndex].default = false;
        }
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [setDefaultCard.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const { clearError } = clientSlice.actions;

export default clientSlice.reducer;
