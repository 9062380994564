import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import { store } from "./store";

import "./App.css";
import MainNav from "./routes";
import Intercom from "Intercom";

export default function App() {
  //enable conekta library in 5 seconds
  React.useEffect(() => {
    const timer = setTimeout(() => {
      const script = document.createElement("script");
      script.src = "https://cdn.conekta.io/js/latest/conekta.js";
      script.async = true;
      document.body.appendChild(script);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <Intercom>
        <HelmetProvider>
          <Provider store={store}>
            {/* <Elements stripe={stripe}> */}
            <MainNav />
            {/* </Elements> */}
          </Provider>
        </HelmetProvider>
      </Intercom>
    </div>
  );
}
