import React from "react";

import classes from "./Logo.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Logo = () => {
    const { clientId } = useSelector((state) => state.auth);
    const logo = `${process.env.REACT_APP_IMAGES_URL}Logo-blanco.png`;
    return (
        <Link to={clientId ? `/${clientId}` : "/login"}>
            <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
    );
};

export default Logo;
