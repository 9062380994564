import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Loading from "../components/UI/Loading";
import AppRoutes from "./switch";

function MainNav() {
  return (
    <Router>
      <React.Suspense fallback={<Loading />}>
        <AppRoutes />
      </React.Suspense>
    </Router>
  );
}

export default MainNav;
