import React from 'react';
import classes from './DrawerToggle.module.scss';

const DrawerToggle = (props) => {
    return (
        <div className={classes.DrawerToggleBox}>
            <div onClick={props.onClick} 
                className={classes.DrawerToggle}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default DrawerToggle;