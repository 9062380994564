import React from "react";
import { IntercomProvider } from "react-intercom-hook";

export default function Intercom(props) {
  return (
    <IntercomProvider
      appId={"gy6gaiw6"}
      autoBoot
    >
      {props.children}
    </IntercomProvider>
  );
}
