import { createSlice } from "@reduxjs/toolkit";
import {
  getExtraPlans,
  getHomeActivationPrice,
  getPlans,
  getPlansByCoverage,
  recurrentPay,
} from "../actions/homeAsyncThunk";

const initialState = {
  loading: "idle",
  currentRequestId: undefined,
  error: null,
  availableExtraPlans: null,
  availablePlans: null,
  activationPrice: null,
  coords: null,
  planId: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.success = false;
    },
    setPlanId: (state, action) => {
      state.planId = action.payload;
    },
  },
  extraReducers: {
    [getPlans.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getPlans.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.availablePlans = action.payload.result;
        state.coords = action.payload.coords;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getPlans.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [getExtraPlans.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getExtraPlans.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.availableExtraPlans = action.payload;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getExtraPlans.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [getPlansByCoverage.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getPlansByCoverage.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.availablePlans = action.payload.plans;
        state.coords = action.payload.coords;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getPlansByCoverage.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    [getHomeActivationPrice.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getHomeActivationPrice.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.error = null;
        state.activationPrice = action.payload;
      }
    },
    [getHomeActivationPrice.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },

    [recurrentPay.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [recurrentPay.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.client.plan.status = "recurrent";
      }
    },
    [recurrentPay.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const { clearError, setPlanId } = homeSlice.actions;

export default homeSlice.reducer;
