import React, { useEffect, useState } from "react";

import Header from "../../components/Navigation/Header";
import Footer from "../../components/Navigation/Footer";

import classes from "./layout.module.scss";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import ScrollToTop from "../../components/Navigation/ScrollToTop";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  ScrollToTop();
  const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);

  const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
  };

  const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
  };

  console.log(props.children);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Header drawerToggleClicked={sideDrawerToggleHandler} />
        <SideDrawer
          open={sideDrawerIsVisible}
          closed={sideDrawerClosedHandler}
        />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
