import React from "react";
import Layout from "hoc/layout/layout";

//Home
const Home = React.lazy(() => import("pages/Inicio/Inicio"));

//NewServices
const NewServices = React.lazy(() => import("pages/NewServices"));
const HomeNewService = React.lazy(() => import("pages/NewServices/Home"));
const MobileNewService = React.lazy(() => import("pages/NewServices/Mobile"));

//Payments
const Payments = React.lazy(() => import("pages/Payments/Payments"));
const Checkout = React.lazy(() => import("pages/Payments/Checkout"));
const Details = React.lazy(() => import("pages/Payments/Details"));
const Methods = React.lazy(() => import("pages/Payments/Methods"));
const Oxxo = React.lazy(() => import("pages/Payments/Oxxo"));
const Recurrent = React.lazy(() => import("pages/Payments/Recurrent"));
const Success = React.lazy(() => import("pages/Payments/Success"));
const Transfer = React.lazy(() => import("pages/Payments/Transfer"));

//Plans
const HomeChange = React.lazy(() => import("pages/Plans/Home/Change"));
const HomeExtra = React.lazy(() => import("pages/Plans/Home/Extra"));
const MobileEsencial = React.lazy(() => import("pages/Plans/Mobile/Esencial"));
const MobilePremium = React.lazy(() => import("pages/Plans/Mobile/Premium"));

//Profile
const Profile = React.lazy(() => import("pages/Profile"));

//Promos
const Referral = React.lazy(() => import("pages/Promos/Referral"));

//Auth
const Auth = React.lazy(() => import("pages/Auth"));
const RecoverPassword = React.lazy(() => import("pages/Auth/RecoverPassword"));
const ConfirmAccount = React.lazy(() => import("pages/Auth/ConfirmAccount"));

const routes = [
  {
    element: <Layout />,
    children: [
      //Home
      {
        path: "/:cid",
        element: <Home />,
      },
      //NewServices
      { path: "/:cid/nuevo-servicio", element: <NewServices /> },
      {
        path: "/:cid/nuevo-servicio/home",
        element: <HomeNewService />,
      },
      {
        path: "/:cid/nuevo-servicio/mobile",
        element: <MobileNewService />,
      },
      //Payments
      {
        path: "/:cid/pagos",
        element: <Payments />,
      },
      {
        path: "/:cid/metodos-de-pago",
        element: <Methods />,
      },
      {
        path: "/:cid/pagos/:pid",
        element: <Details />,
      },
      {
        path: "/:cid/checkout",
        element: <Checkout />,
      },
      {
        path: "/:cid/pago-en-oxxo",
        element: <Oxxo />,
      },
      {
        path: "/:cid/pago-transfer",
        element: <Transfer />,
      },
      {
        path: "/:cid/success",
        element: <Success />,
      },
      // {
      //     path: "/:cid/domiciliacion",
      //     element: <Recurrent,
      // },

      //Plans
      {
        path: "/:cid/home/cambio-de-plan",
        element: <HomeChange />,
      },
      {
        path: "/:cid/home/extra",
        element: <HomeExtra />,
      },
      {
        path: "/:cid/mobile/recarga",
        element: <MobileEsencial />,
      },
      {
        path: "/:cid/mobile/premium",
        element: <MobilePremium />,
      },
      //Profile
      {
        path: "/:cid/perfil",
        element: <Profile />,
      },
      //Promos
      {
        path: "/:cid/referidos",
        element: <Referral />,
      },
      {
        path: "/success",
        element: <Success />,
      },
    ],
  },
];

export const authRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Auth />,
      },
      {
        path: "/recuperar/:recoverToken",
        element: <RecoverPassword />,
      },
      {
        path: "/confirmar/:confirmToken",
        element: <ConfirmAccount />,
      },
    ],
  },
];

export default routes;
