import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import clientReducer from "./slices/clientSlice";
import orderReducer from "./slices/orderSlice";
import homeReducer from "./slices/homeSlice";
import mobileReducer from "./slices/mobileSlice";
import travelReducer from "./slices/travelSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        client: clientReducer,
        order: orderReducer,
        home: homeReducer,
        mobile: mobileReducer,
        travel: travelReducer,
    },
});
