import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

import configWithToken from "./credentialsConfig";
import errorHandler from "./errorHandler";

export const getCompartCarga = createAsyncThunk(
  "mobile/getCompartCarga",
  async (address, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().mobile;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.post(`${process.env.REACT_APP_BACKEND_URL}/koonol/compartcarga`, {
        address,
      }),
      rejectWithValue
    );
  }
);

export const getMobilePlans = createAsyncThunk(
  "mobile/getMobilePlans",
  async (arg, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().mobile;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.get(`${process.env.REACT_APP_BACKEND_URL}/plan/?product_type=mbb`),
      rejectWithValue
    );
  }
);

export const getMobileActivationPrice = createAsyncThunk(
  "mobile/getMobileActivationPrice",
  async (arg, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().mobile;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/2`),
      rejectWithValue
    );
  }
);

export const startPortability = createAsyncThunk(
  "mobile/startPortability",
  async (
    { portedDid, serviceId },
    { getState, requestId, rejectWithValue }
  ) => {
    const { currentRequestId, loading, error } = getState().mobile;
    const { clientId, token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/portability/new`,
        { portedDid, serviceId },
        configWithToken(token)
      ),
      rejectWithValue
    );
  }
);
