const conektaHelper = {
  initConekta: () => {
      window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_KEY);
      window.Conekta.setLanguage("es");
  },
  getCardBrand: (cardNumber) => {
      return window.Conekta.card.getBrand(cardNumber);
  },
  validateCardNumber: (cardNumber) => {
      return window.Conekta.card.validateNumber(cardNumber);
  },
  validateCvc: (cvc) => {
      return window.Conekta.card.validateCVC(cvc);
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
      return window.Conekta.card.validateExpirationDate(
          expiryMonth,
          `20${expiryYear}`
      );
  },
  tokenize: (card) => {
      return new Promise((resolve, reject) => {
          const tokenParams = {
              card: {
                  number: card.number,
                  name: card.name,
                  exp_year: "20" + card.year,
                  exp_month: card.month,
                  cvc: card.cvc,
              },
          };
          const tokenRequest = window.Conekta.Token.create(tokenParams);
          tokenRequest.onload = () => {
              if (tokenRequest.status >= 200 && tokenRequest.status < 300) {
                  const response = JSON.parse(tokenRequest.response);
                  if (response.object === "error") {
                      reject(response);
                  }
                  resolve(response);
              } else {
                  reject(JSON.parse(tokenRequest.statusText));
              }
          };
          tokenRequest.onerror = () =>
              reject(JSON.parse(tokenRequest.statusText));
      });
  },
};

export default conektaHelper;
