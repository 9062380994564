import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

import configWithToken from "./credentialsConfig";
import errorHandler from "./errorHandler";

export const getPromotions = createAsyncThunk(
  "sale/getPromotions",
  async (imei, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().sale;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    return await errorHandler(
      Axios.get(
        `${process.env.REACT_APP_process.env.REACT_APP_BACKEND_URL}/promotion/`
      ),
      rejectWithValue
    );
  }
);

export const verifyPromotion = createAsyncThunk(
  "order/verifyPromotion",
  async ({ promoCode }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, newOrder, error } = getState().order;
    const { clientId, token } = getState().auth;
    const { product, selectedPlan } = newOrder;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    const body = {
      planId: selectedPlan.id,
      productId: product ? product.id : null,
      promoCode,
      orderType: newOrder.type,
    };
    return await errorHandler(
      Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/promotion/verify`,
        body,
        configWithToken(token)
      ),
      rejectWithValue
    );
  }
);

export const updateSubscription = createAsyncThunk(
  "order/updateSubscription",
  async ({ card }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, newOrder, error } = getState().order;
    const { clientId, token } = getState().auth;

    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    const body = {
      serviceId: newOrder.serviceId,
      planId: newOrder.selectedPlan.id,
      card: card.id,
    };
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/subscription`,
        body,
        configWithToken(token)
      );

      return {
        total: data.total,
        status: data.subscription ? data.subscription.status : data.status,
        date: data.subscription && data.subscription.billingCycleEnd * 1000,
        message: data.details,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        message: "Hubo un error al procesar tu solicitud. Contáctanos",
        code: 500,
        slug: "unknown_error",
      });
    }
  }
);

export const payWithCard = createAsyncThunk(
  "order/payWithCard",
  async ({ card, useRefCredit }, { getState, requestId, rejectWithValue }) => {
    const { newOrder, loading, error, currentRequestId } = getState().order;
    const { coords } = getState().home;
    const { clientId, token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    const body = {
      monthlyInstallments: 1,
      promoId: newOrder.activePromo?.promoId || "",
      productId: newOrder.product ? newOrder.product.id : null,
      planId: newOrder.selectedPlan.id,
      paymentSourceId: card.id,
      sellerId: process.env.REACT_APP_SELLER_ID,
      orderType: newOrder.type,
      serviceId: newOrder.serviceId,
      usingCreditRef: useRefCredit,
      portingDid: newOrder.portingDid,
      coords: coords || "",
      payMethod: "card_payment",
      isActive: false,
      preregistered: false,
    };
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/create-order`,
        body,
        configWithToken(token)
      );

      return {
        total: data.total,
        status: data.status,
        message: data.details,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        message: "Hubo un error al procesar tu solicitud. Contáctanos",
        code: 500,
        slug: "unknown_error",
      });
    }
  }
);

export const payWithCash = createAsyncThunk(
  "order/payWithCash",
  async (useRefCredit, { getState, requestId, rejectWithValue }) => {
    const { newOrder, loading, error, currentRequestId } = getState().order;
    const { coords } = getState().home;
    const { clientId, token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    try {
      const body = {
        promoId: newOrder.activePromo?.promoId || "",
        productId: newOrder.product ? newOrder.product.id : null,
        planId: newOrder.selectedPlan.id,
        sellerId: process.env.REACT_APP_SELLER_ID,
        orderType: newOrder.type,
        serviceId: newOrder.serviceId,
        usingCreditRef: useRefCredit,
        portingDid: newOrder.portingDid,
        coords: coords || "",
        payMethod: "cash_payment",
        isActive: false,
        preregistered: false,
      };
      const { data } = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/create-order`,
        body,
        configWithToken(token)
      );
      if (data.status === "fail") {
        rejectWithValue(data);
      }
      return {
        total: data.total,
        status: data.status,
        message: data.message,
        reference: data.reference,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        message: "Hubo un error al procesar tu solicitud. Contáctanos",
        code: 500,
        slug: "unknown_error",
      });
    }
  }
);

export const payWithTransfer = createAsyncThunk(
  "order/payWithTransfer",
  async (useRefCredit, { getState, requestId, rejectWithValue }) => {
    const { newOrder, loading, error, currentRequestId } = getState().order;
    const { coords } = getState().home;
    const { clientId, token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    try {
      const body = {
        promoId: newOrder.activePromo?.promoId || "",
        productId: newOrder.product ? newOrder.product.id : null,
        planId: newOrder.selectedPlan.id,
        sellerId: process.env.REACT_APP_SELLER_ID,
        orderType: newOrder.type,
        serviceId: newOrder.serviceId,
        usingCreditRef: useRefCredit,
        portingDid: newOrder.portingDid,
        coords: coords || "",
        payMethod: "transfer",
        isActive: false,
        preregistered: false,
      };
      const { data } = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/create-order`,
        body,
        configWithToken(token)
      );
      if (data.status === "fail") {
        rejectWithValue(data);
      }
      return {
        total: data.total,
        status: data.status,
        message: data.message,
        reference: data.reference,
        cuenta: data.cuenta?.cuenta,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        message: "Hubo un error al procesar tu solicitud. Contáctanos",
        code: 500,
        slug: "unknown_error",
      });
    }
  }
);
