import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import { getClientById } from "store/actions/clientAsyncThunk";
import { useAuth } from "utils/hooks/useAuth";
import routes, { authRoutes } from "./routes";

export default function () {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.client);
  const { clientId } = useSelector((state) => state.auth);
  const { token } = useAuth();

  React.useEffect(() => {
    async function fecthClient() {
      await dispatch(getClientById());
    }
    if (!client && token) {
      fecthClient();
    }
  }, [client, token]);
  const routesWithRedirection = [
    ...routes,
    { path: "*", element: <Navigate to={`/${clientId && clientId}`} /> },
  ];
  const authRoutesWithRedirection = [
    ...authRoutes,
    { path: "*", element: <Navigate to={"/"} /> },
  ];
  let element = useRoutes(
    token !== null ? routesWithRedirection : authRoutesWithRedirection
  );
  return element;
}
