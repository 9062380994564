import { createSlice } from "@reduxjs/toolkit";
import {
    getCompartCarga,
    getMobileActivationPrice,
    getMobilePlans,
    startPortability,
} from "../actions/mobileAsyncThunk";

const initialState = {
    loading: "idle",
    currentRequestId: undefined,
    error: null,
    availablePlans: null,
    activationPrice: null,
    success: false,
};

const mobileSlice = createSlice({
    name: "mobile",
    initialState: initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
            state.success = false;
        },
    },
    extraReducers: {
        [getCompartCarga.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [getCompartCarga.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
            }
        },
        [getCompartCarga.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
        [getMobilePlans.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [getMobilePlans.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.availablePlans = action.payload;
            }
        },
        [getMobilePlans.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
        [getMobileActivationPrice.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [getMobileActivationPrice.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.activationPrice = action.payload;
            }
        },
        [getMobileActivationPrice.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
        [startPortability.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [startPortability.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.success = true;
            }
        },
        [startPortability.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
    },
});

export const { clearError } = mobileSlice.actions;

export default mobileSlice.reducer;
