import React from "react";

import classes from "./Header.module.scss";
import Logo from "../../UI/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import { AiOutlinePhone } from "react-icons/ai";
import { useSelector } from "react-redux";


const Header = (props) => {
    const { clientId } = useSelector((state) => state.auth);
    return (
        <>
            <div className={classes.phone}>
                <p>
                    <a href="tel:8003239777"> <AiOutlinePhone/> 800 1111 919</a>
                </p>
            </div>
            <header className={classes.header}>
                <Logo />
                {clientId && <DrawerToggle onClick={props.drawerToggleClicked} />}
                <nav className={classes.desktopOnly}>
                    <NavigationItems />
                </nav>
            </header>
        </>
    );
};

export default Header;