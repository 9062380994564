import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "./NavigationItems.module.scss";
import { AiOutlineLogout } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { logout } from "../../../store/slices/authSlice";

const Dropdown = (props) => {
    const node = useRef();
    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            props.callbackFromParent(true);
            return;
        }
        // outside click
        props.callbackFromParent(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <ul className={classes.dropDown} ref={node}>
            {props.children}
        </ul>
    );
};

const NavigationItems = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clientId } = useSelector((state) => state.auth);
    const [dropDown, setdropDown] = useState(false);

    const callBack = (dropDown) => {
        setdropDown(dropDown);
    };
    const { pathname } = window.location;

    const mainClickHandler = () => {
        props.closed && props.closed();
        setdropDown(false);
    };
    return (
        <>
            {clientId && (
                <ul className={classes.navList}>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            onClick={mainClickHandler}
                            activeClassName={classes.activeNavItem}
                            exact
                            to={`/${clientId}`}
                        >
                            Inicio
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            onClick={mainClickHandler}
                            activeClassName={classes.activeNavItem}
                            exact
                            to={`/${clientId}/referidos`}
                        >
                            Referidos
                        </NavLink>
                    </li>
                    <li onClick={() => setdropDown((prev) => !prev)}>
                        <p
                            className={
                                pathname.includes("pago")
                                    ? classes.activeNavItem
                                    : classes.navItem
                            }
                        >
                            Pagos{" "}
                            <IoIosArrowDown className={classes.downIcon} />
                        </p>
                        {dropDown && (
                            <Dropdown callbackFromParent={callBack}>
                                <li>
                                    <NavLink
                                        className={classes.navItem}
                                        exact
                                        to={`/${clientId}/metodos-de-pago`}
                                        onClick={mainClickHandler}
                                    >
                                        Metodos de Pago
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={classes.navItem}
                                        exact
                                        to={`/${clientId}/pagos`}
                                        onClick={mainClickHandler}
                                    >
                                        Pagos
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink
                                        className={classes.navItem}
                                        exact
                                        to={`/${clientId}/domiciliacion`}
                                        onClick={mainClickHandler}
                                    >
                                        Domiciliacion
                                    </NavLink>
                                </li> */}
                            </Dropdown>
                        )}
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            onClick={props.closed}
                            activeClassName={classes.activeNavItem}
                            exact
                            to={`/${clientId}/perfil`}
                        >
                            Perfil
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            onClick={() => {
                                props.closed && props.closed();
                                dispatch(logout());
                                window.location.reload("/login");
                            }}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/login"
                        >
                            <AiOutlineLogout />
                        </NavLink>
                    </li>
                </ul>
            )}
        </>
    );
};

export default NavigationItems;
