import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPassword,
  confirmAccount,
  login,
  recoverPassword,
} from "../actions/authAsyncThunk";

const initialState = {
  loading: "idle",
  currentRequestId: undefined,
  error: null,
  clientId: null,
  token: null,
  tokenExpDate: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.success = false;
    },
    cancelRequest: (state) => {
      state.currentRequestId = null;
    },
    autoLogin: (state, action) => {
      state.clientId = action.payload.clientId;
      state.token = action.payload.token;
      state.tokenExpDate = action.payload.expDate;
    },
    logout: (state) => {
      localStorage.removeItem("clientData");
    },
  },
  
  extraReducers: {
    //login
    [login.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [login.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.error = null;
        state.token = action.payload.accessToken;
        state.clientId = action.payload.client.id;
        const tokenExpDate = new Date().getTime() + action.payload.expirationDate;
        state.tokenExpDate = tokenExpDate;
        const clientDataString = JSON.stringify({
          clientId: action.payload.client.id,
          token: action.payload.accessToken,
          expiration: tokenExpDate,
        });
        localStorage.setItem("clientData", clientDataString);
      }
    },

    [login.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },

    //forgot-password
    [forgotPassword.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [forgotPassword.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.success = true;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [forgotPassword.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },

    //recover-password
    [recoverPassword.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [recoverPassword.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.success = true;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [recoverPassword.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },

    //confirm-account
    [confirmAccount.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [confirmAccount.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.success = true;
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [confirmAccount.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const {
  clearError,
  cancelRequest,
  autoLogin,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
