import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autoLogin, logout } from "../../store/slices/authSlice";

let logoutTimer;

export const useAuth = () => {
  const dispatch = useDispatch();
  const { token, tokenExpDate } = useSelector((state) => state.auth);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("clientData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      dispatch(
        autoLogin({
          clientId: storedData.clientId,
          token: storedData.token,
          expDate: storedData.expiration,
        })
      );
    } else {
      //logs out if no valid client data or if token is already expired.
      dispatch(logout());
    }
  }, [autoLogin]);

  useEffect(() => {
    //sets a timer that automatically logs out when the token expires.
    if (token && tokenExpDate) {
      const remainingTime = tokenExpDate - new Date().getTime();
      logoutTimer = setTimeout(() => dispatch(logout()), remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpDate]);

  return { token };
};
