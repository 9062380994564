import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: "idle",
    currentRequestId: undefined,
    error: null,
};

const travelSlice = createSlice({
    name: "travel",
    initialState: initialState,
    reducers: {},
    extraReducers: {},
});

export const {} = travelSlice.actions;

export default travelSlice.reducer;
