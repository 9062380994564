import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

import errorHandler from "./errorHandler";

export const confirmAccount = createAsyncThunk(
  "auth/confirmAccount",
  async (
    { confirmToken, password },
    { getState, requestId, rejectWithValue }
  ) => {
    const { currentRequestId, loading } = getState().auth;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    return await errorHandler(
      Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/confirm/${confirmToken}`,
        { password }
      ),
      rejectWithValue
    );
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().auth;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    return await errorHandler(
      Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/forgot`,
        { email, type: "client" }
      ),
      rejectWithValue
    );
  }
);

export const recoverPassword = createAsyncThunk(
  "auth/recoverPassword",
  async (
    { recoverToken, password },
    { getState, requestId, rejectWithValue }
  ) => {
    const { currentRequestId, loading } = getState().auth;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    return await errorHandler(
      Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/reset/${recoverToken}`,
        { password, type: "client" }
      ),
      rejectWithValue
    );
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (authData, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().auth;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const data = {
      email: authData.email,
      password: authData.password,
      type: "client",
    };

    return await errorHandler(
      Axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, data),
      rejectWithValue
    );
  }
);
