import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

import configWithToken from "./credentialsConfig";
import errorHandler from "./errorHandler";

export const getPlans = createAsyncThunk(
  "client/getPlans",
  async (arg, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().home;
    const { clientId, token } = getState().auth;
    const { newOrder } = getState().order;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/service/${newOrder.serviceId}/available-plans`,
        configWithToken(token)
      ),
      rejectWithValue
    );
  }
);

export const getHomeActivationPrice = createAsyncThunk(
  "home/getHomeActivationPrice",
  async (arg, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().home;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/1`),
      rejectWithValue
    );
  }
);

export const getPlansByCoverage = createAsyncThunk(
  "client/getPlansByCoverage",
  async (address, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().home;
    const { clientId, token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    try {
      const {
        data: { result: coverage, coordinates: coords },
      } = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/location/coverage`,
        address
      );
      const { data: plansByCoverage } = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/plan/speed/${coverage}`
      );
      return { plans: plansByCoverage, coords: coords };
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        message: "Hubo un error al procesar tu solicitud. Contáctanos",
        code: 500,
        slug: "unknown_error",
      });
    }
  }
);

export const getExtraPlans = createAsyncThunk(
  "client/getExtraPlans",
  async (arg, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error, planId } = getState().home;
    const { token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/plan/${planId}/extra`,
        configWithToken(token)
      ),
      rejectWithValue
    );
  }
);

export const recurrentPay = createAsyncThunk(
  "home/recurrentPay",
  async (cardId, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().home;
    const { clientId, token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.post(
        `${process.env.REACT_APP_process.env.REACT_APP_BACKEND_URL}/client/${clientId}/subscription/activate`,
        { cardId: cardId },
        configWithToken(token)
      ),
      rejectWithValue
    );
  }
);

export const cancelRecurrentPay = createAsyncThunk(
  "home/cancelRecurrentPay",
  async (arg, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().home;
    const { clientId, token } = getState().auth;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.post(
        `${process.env.REACT_APP_process.env.REACT_APP_BACKEND_URL}/client/${clientId}/subscription/cancel`,
        null,
        configWithToken(token)
      ),
      rejectWithValue
    );
  }
);
