import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

import errorHandler from "./errorHandler";
import conekta from "utils/conekta";
import configWithToken from "./credentialsConfig";

export const getClientById = createAsyncThunk(
    "client/getClientById",
    async (
        _,
        { getState, requestId, rejectWithValue }
    ) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/info`,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const updateNickname = createAsyncThunk(
    "client/updateNickname",
    async (
        { serviceId, nickname },
        { getState, requestId, rejectWithValue }
    ) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/service/${serviceId}/nickname`,
                {nickname},
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const updateClient = createAsyncThunk(
    "client/updateClient",
    async (clientInfo, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/info`,
                clientInfo,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const updateClientPassword = createAsyncThunk(
    "client/updateClientPassword",
    async (clientPassword, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/password`,
                clientPassword,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const getPayments = createAsyncThunk(
    "client/getPayments",
    async (arg, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/payments?status=paid`,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const getPaymentDetails = createAsyncThunk(
    "client/getPaymentDetails",
    async (paymentId, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/payments/${paymentId}`,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const getMethods = createAsyncThunk(
    "client/getMethods",
    async (arg, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/payments/methods`,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const getSubscription = createAsyncThunk(
    "client/getSubscription",
    async (arg, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/subscription`,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const addCard = createAsyncThunk(
    "client/addCard",
    async (card, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        conekta.initConekta();
        const conektaToken = await conekta.tokenize(card);
        const body = {
            tokenId: conektaToken.id,
        };
        return await errorHandler(
            Axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/payments/methods`,
                body,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const deleteCard = createAsyncThunk(
    "client/deleteCard",
    async (cardId, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/payments/methods/${cardId}`,
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const setDefaultCard = createAsyncThunk(
    "client/setDefaultCard",
    async (cardId, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/method/default`,
                { cardId: cardId },
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);

export const generateInvoice = createAsyncThunk(
    "client/generateInvoice",
    async ({ paymentId, rfc }, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading, error } = getState().client;
        const { clientId, token } = getState().auth;
        if (
            loading !== "pending" ||
            requestId !== currentRequestId ||
            error !== null
        ) {
            return;
        }
        return await errorHandler(
            Axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/client/${clientId}/invoice/${paymentId}`,
                { rfc },
                configWithToken(token)
            ),
            rejectWithValue
        );
    }
);
