import { createSlice } from "@reduxjs/toolkit";
import {
    payWithCash,
    payWithCard,
    getPromotions,
    verifyPromotion,
    updateSubscription,
    payWithTransfer,
} from "../actions/orderAsyncThunk";

const initialState = {
    newOrder: {
        address: null,
        product: null,
        selectedPlan: null,
        promotion: null,
        fulfilled: false,
        rejectedMessage: null,
        rejectedCounter: 0,
        reference: null,
        activePromo: null,
        portingDid: '',
        serviceId: undefined,
        type: "",
        total: 0,
    },
    availablePromos: [],
    loading: "idle",
    currentRequestId: undefined,
    error: null,
};

const orderSlice = createSlice({
    name: "order",
    initialState: initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        clearState: (state) => {
            return initialState;
        },
        addProduct: (state, action) => {
            state.newOrder.product = action.payload;
        },
        addPlan: (state, action) => {
            state.newOrder.selectedPlan = action.payload;
        },
        addPortingDid: (state, action) => {
            state.newOrder.portingDid = action.payload;
        },
        setAddress: (state, action) => {
            state.newOrder.address = action.payload;
        },
        setOrderType: (state, action) => {
            state.newOrder.type = action.payload;
        },
        setServiceId: (state, action) => {
            state.newOrder.serviceId = action.payload;
        },
        clearRejectedCounter: (state) => {
            state.newOrder.rejectedCounter = 0;
            state.newOrder.rejectedMessage = null;
        },
        clearRejectedMessage: (state) => {
            state.newOrder.rejectedMessage = null;
        },
        cancelPromo: (state, action) => {
            state.newOrder.activePromo = null;
        },
    },
    extraReducers: {
        [getPromotions.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [getPromotions.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.availablePromos = action.payload;
            }
        },
        [getPromotions.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
        [verifyPromotion.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [verifyPromotion.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.newOrder.activePromo = action.payload.promotion;
                state.error = null;
            }
        },
        [verifyPromotion.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
        [updateSubscription.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [updateSubscription.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                if (action.payload.status === "active") {
                    state.newOrder.fulfilled = true;
                    state.newOrder.total = action.payload.total;
                    state.newOrder.nextPayment = action.payload.date;

                } else if (action.payload.status === "fail") {
                    state.newOrder.rejectedMessage = action.payload.message;
                    state.newOrder.rejectedCounter++;
                }
            }
        },
        [updateSubscription.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
        [payWithCard.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [payWithCard.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                if (action.payload.status === "success") {
                    state.newOrder.fulfilled = true;
                    state.newOrder.total = action.payload.total;
                } else if (action.payload.status === "fail") {
                    state.newOrder.rejectedMessage = action.payload.message;
                    state.newOrder.rejectedCounter++;
                }
            }
        },
        [payWithCard.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },

        [payWithCash.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [payWithCash.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.newOrder.reference = action.payload.reference;
                state.newOrder.fulfilled = true;
                state.newOrder.total = action.payload.total;
            }
        },
        [payWithCash.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },

        [payWithTransfer.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [payWithTransfer.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.newOrder.reference = action.payload.reference;
                state.newOrder.cuenta = action.payload.cuenta;
                state.newOrder.fulfilled = true;
                state.newOrder.total = action.payload.total;
            }
        },
        [payWithTransfer.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
    },
});

export const {
    addProduct,
    addPlan,
    addPortingDid,
    setAddress,
    setOrderType,
    setServiceId,
    clearError,
    clearRejectedCounter,
    clearRejectedMessage,
    addPromo,
    cancelPromo,
    clearState,
} = orderSlice.actions;

export default orderSlice.reducer;
